import React from "react";
import "./static/css/normalize.css";
import "./static/css/font-families.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Main from "./administration/Routes/Main";
import Appointments from "./administration/Routes/Appointments";
import Invoices from "./administration/Routes/Invoices";
import Homepage from "./webpage/Routes/Homepage";
import Formula from "./webpage/Routes/Formula";
import SuccessPage from "./webpage/Routes/SuccessPage";
import Booking from "./webpage/Routes/Booking";
import Timeslots from "./administration/Routes/Timeslots";
import VerifyCheck from "./webpage/Routes/VerifyCheck";
import Verify from "./webpage/Routes/Verify";
import ProtectedRoute from "./webpage/Routes/ProtectedRoute";
import { Login } from "./administration/components/login";
import Imprint from "./webpage/Routes/legal/Imprint";
import PrivacyPolicy from "./webpage/Routes/legal/PrivacyPolicy";
import Contact from "./webpage/Routes/legal/Contact";
import { DateField } from "./webpage/components/date-field";
import Restore from "./webpage/Routes/Restore";
import { SearchBar } from "./administration/components/searchbar";

export default function App() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={"/"} />} />
      {/* <Route
        exact
        path="/test"
        element={<SearchBar target={"services"} onValueChange={() => {}} />}
      /> */}
      <Route exact path="/" element={<Homepage />} />
      <Route exact path="/formula" element={<Formula />} />
      <Route
        exact
        path="/formula/success"
        element={
          <ProtectedRoute checkForSubmission>
            <SuccessPage isSuccessfull={true} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/formula/fail"
        element={
          <ProtectedRoute checkForSubmission>
            <SuccessPage isSuccessfull={false} />
          </ProtectedRoute>
        }
      />
      <Route exact path="/booking" element={<Booking />} />
      <Route exact path="/verify" element={<Verify />} />
      <Route
        exact
        path="/verify/:token"
        element={<VerifyCheck path="verify" />}
      />
      <Route exact path="/restore" element={<Restore />} />
      <Route
        exact
        path="/restore/:token"
        element={<VerifyCheck path="restore" />}
      />
      <Route exact path="/imprint" element={<Imprint />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/contact" element={<Contact />} />

      <Route exact path="/authenticate" element={<Login />} />
      <Route
        exact
        path="/billing"
        element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/billing/invoices"
        element={
          <ProtectedRoute>
            <Invoices />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/billing/appointments"
        element={
          <ProtectedRoute>
            <Appointments />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/billing/timeslots"
        element={
          <ProtectedRoute>
            <Timeslots />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
