//weekString = 2024-W24
import { format } from "date-fns";
export function getDateRange(dateString, dayCount = 7) {
  const date = new Date(dateString);
  let dates = [];
  dates.push(new Date(dateString));
  for (let i = 0; i < dayCount; i++) {
    dates.push(date.setDate(date.getDate() + 1));
  }
  return dates.map((date) => format(new Date(date), "dd.MM.yyyy"));
}

export function addMinutesToTime(time, minutesToAdd) {
  const dateWithTime = new Date(`1970/01/01 ${time}`).getTime();
  const dateWithAddedTime = new Date(dateWithTime + minutesToAdd * 60000);

  return dateWithAddedTime.toLocaleTimeString("en-UK", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function formatDate(dateString, locales, options) {
  return new Date(dateString).toLocaleString(locales, options);
}
