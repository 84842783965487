import { Thead } from "../components/table-head";
import classes from "../css/Table.module.css";
import { TimeslotForm } from "../components/timeslot-form";
import { useEffect, useState } from "react";
import { Trow } from "../components/table-element";
import { useNavigate } from "react-router-dom";
import { getDateRange } from "../../utils/weekFormatter";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export default function Timeslots() {
  const [timeslots, setTimeslots] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);
  const [week, setWeek] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(json_url + "/data/timeslots?claimed=false", {
      headers: { "x-api-key": sessionStorage.getItem("token") },
    })
      .then((response) => validateResponse(response).json())
      .then((result) => {
        setTimeslots(result);
      })
      .catch((error) => {
        console.error("Error whilst fetching timeslot data: ", error);
      })
      .finally(setLoading(false));
  }, [loadingCount]);

  function convertToTrow(timeslots) {
    if (!timeslots) return <tr>Keine Daten verfügbar!</tr>;
    if (loading) return <tr>Daten werden geladen...</tr>;

    let timeslotsCopy = timeslots.slice();

    if (week) {
      timeslotsCopy = timeslots.filter((timeslot) =>
        getDateRange(week).includes(timeslot.date)
      );
    }

    return timeslotsCopy.map((timeslot) => {
      const buttons = { Entfernen: () => removeTimeslot(timeslot.timeslotID) };
      return (
        <Trow
          date={timeslot.date}
          begin={timeslot.begin}
          end={timeslot.end}
          quantity={timeslot.quantity}
          buttons={buttons}
        />
      );
    });
  }

  function removeTimeslot(timeslotID) {
    fetch(json_url + `/data/timeslots/remove?id=${timeslotID}`, {
      headers: { "x-api-key": sessionStorage.getItem("token") },
    })
      .then((response) => response.json())
      .then((message) => {
        console.log("Successfully fetched timeslotID: ", message);
      })
      .catch((error) => {
        console.error("Error fetching timeslotID: ", error);
      })
      .finally(() => {
        setLoadingCount(loadingCount + 1);
      });
  }

  return (
    <div className={classes.body}>
      <div className={classes.Container}>
        <h1 className={classes.h1}>Timeslots</h1>
        <div className={classes["week-container"]}>
          <div>
            <span>Woche vom: </span>
            <input
              type="date"
              onChange={(e) => setWeek(e.target.value)}
              value={week}
            />
          </div>

          <button className={classes.button} onClick={() => setWeek("")}>
            Zurücksetzen
          </button>
        </div>

        <div className={classes["list-container"]}>
          <table className={classes["table"]}>
            <Thead
              titles={[
                "Datum",
                "Beginn",
                "Ende",
                "Anzahl (Schulstunden)",
                "Aktion",
              ]}
            />
            <tbody>{convertToTrow(timeslots)}</tbody>
          </table>
        </div>
        <div className={classes.buttons}>
          <button
            className={classes.button}
            onClick={() =>
              navigate("/billing", {
                state: { isAuthenticated: true },
              })
            }
          >
            Zurück
          </button>
          <TimeslotForm
            handleRefresh={() => setLoadingCount(loadingCount + 1)}
          />
        </div>
      </div>
    </div>
  );
}
