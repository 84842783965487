import { useState } from "react";
import { Select } from "./dropdown";
import classes from "../css/Main.module.css";
import { SearchBar } from "./searchbar";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export function AppointmentForm() {
  const [visibility, setVisibility] = useState(false);
  const [appointment, setAppointment] = useState(["", "", "", 0, false]);
  function toggleVisibility() {
    setVisibility(!visibility);
  }

  function setStudentID(id) {
    setAppointment([id, ...appointment.slice(1)]);
  }

  function setAppointmentID(id) {
    setAppointment([appointment[0], id, ...appointment.slice(2)]);
  }

  function setDate(date) {
    setAppointment([...appointment.slice(0, 2), date, ...appointment.slice(3)]);
  }

  function setQuantity(quantity) {
    setAppointment([
      ...appointment.slice(0, 3),
      quantity,
      ...appointment.slice(4),
    ]);
  }

  function setClaimed(checked) {
    setAppointment([...appointment.slice(0, 4), checked]);
  }

  function submit() {
    if (appointment[3] <= 0) {
      alert("Anzahl muss größer als 0 sein!");
      return;
    }

    if (appointment[0] === "" || appointment[1] === "") {
      alert("Wähle einen Schüler und eine Leistung aus der Liste aus!");
      return;
    }

    fetch(json_url + "/submit-appointment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": sessionStorage.getItem("token"),
      },
      body: JSON.stringify(appointment),
    })
      .then((response) => validateResponse(response).json())
      .then((data) => {
        console.log("Succesfully sent appointment:", data);
        setVisibility(false);
        setAppointment(["", "", "", 0, false]);
      })
      .catch((error) => {
        console.error("Error sending appointment to server:", error);
      });
  }

  let style = { display: visibility ? "block" : "none" };

  return (
    <>
      <button className={classes.button} onClick={toggleVisibility}>
        + Termin
      </button>
      <div className={classes.overlay} style={style}></div>
      <div
        className={`${classes.appointmentContainer} ${classes.containers}`}
        style={style}
      >
        <h2 className={classes.h2}>Neuen Termin festlegen</h2>
        <div
          className={classes["menu-burger"]}
          onClick={toggleVisibility}
        ></div>
        <label className={classes.label}>Kunde:</label>
        <Select
          onDropdownChange={setStudentID}
          value={appointment[0]}
          target={"students"}
        />
        {/* <SearchBar
          onValueChange={setStudentID}
          value={appointment[0]}
          target={"students"}
        /> */}
        <label className={classes.label}>Leistung:</label>
        <Select
          onDropdownChange={setAppointmentID}
          value={appointment[1]}
          target={"services"}
        />
        {/* <SearchBar
          onValueChange={setAppointmentID}
          value={appointment[1]}
          target={"services"}
        /> */}
        <label className={classes.label}>Datum:</label>
        <input
          className={classes.input}
          type="datetime-local"
          id="dateInput"
          value={appointment[2]}
          onChange={(event) => setDate(event.target.value)}
          required
        />
        <label className={classes.label}>Anzahl:</label>
        <input
          className={classes.input}
          type="number"
          id="quantityInput"
          step="any"
          value={appointment[3]}
          onChange={(event) => setQuantity(event.target.value)}
          required
        />
        <label className={classes.label}>besucht?</label>
        <input
          className={classes.input}
          type="checkbox"
          id="claimedInput"
          checked={appointment[4]}
          onChange={(event) => setClaimed(event.target.checked)}
        />
        <button className={classes.button} type="button" onClick={submit}>
          Erstellen
        </button>
      </div>
    </>
  );
}
